import { computed } from 'vue';
import { useStore } from 'vuex';

export function usePage() {
  const store = useStore();
  return computed(() => store.state.router.page);
}

export function usePageContent() {
  const page = usePage();
  return computed(() => page.value?.content);
}
