<template>
  <div class="Aufklappbar part">
    <div class="constrain">
      <h1 class="title a" style="color: var(--color-area)">
        {{ payload.titel }}
      </h1>
      <accordion :items="payload.aufklappelemente">
        <template #divider>
          <span class="divider" />
        </template>

        <template #header="{ item, expanded }">
          <span class="heading a" :aria-expanded="expanded">
            <span class="plus">+</span> {{ item.titel }}
          </span>
        </template>

        <template #default="{ item }">
          <div class="content">
            <span v-html="item.text" />
            <xstanding-link v-if="item.link" :to="item.link" class="link">
              {{ item.linktext }}
            </xstanding-link>
          </div>
        </template>
      </accordion>
    </div>
  </div>
</template>

<script>
import Dynamic from '../structure/Dynamic.vue';
import { usePageContent } from '@/hooks/content';

export default {
  components: { Dynamic },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const content = usePageContent();
    return {
      content,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

:deep(p) {
  margin-bottom: 1.5rem;
}

.link {
  @extend .li1;
}

.heading {
  font-weight: 300 !important;
  display: block;
  padding: 1rem 0;
  cursor: pointer;
  transition:
    opacity var(--speed-fast) ease,
    color var(--speed-fast) ease;

  .plus {
    display: inline-block;
    will-change: transform;
    transition: transform var(--speed-fast) ease;
  }

  &[aria-expanded="true"] .plus {
    transform: rotate(45deg);
  }

  &:hover {
    color: var(--color-active);
    opacity: 0.7;
  }
}

.title {
  position: relative;
  color: var(--color-area);
  padding-top: 1rem;
  font-weight: 300 !important;

  &::before {
    @include full-overlay();
    content: "";
    border-top: 2px solid var(--color-area);
    width: 3rem;
    pointer-events: none;
  }
}

.content {
  @extend .f1;
  padding-bottom: 2rem;
}
</style>
